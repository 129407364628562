.player {
    height: 100%;
    display: grid;
    grid-template-rows: auto 6rem;
    position: relative;
    // background-color: blue;
}
.screen {
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%
    }
    .track-counter {
        position: absolute;
        left:2rem;
        top: 2rem;
    }
}
