.file-browser {
    padding: 2rem;
    display: flex;
    justify-content: center;
    .directorySetting{
        // height: 100%;
        // width: 100%;
        justify-self: center;
        align-self: center;
    }
}