.directory {
    margin: 1rem;
    cursor: pointer;
    height: max-content;
    a{
        display: block;
        height: max-content;
    }
    .no-image{
        height:150px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: gray;
    }
}