.timer {
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    cursor: pointer;
    > div {
        margin: 0.5rem; 
    }
}
.timer:hover {
    .currentTime::after {
        content: '';
        position: absolute;
        right: -0.4rem;
        top: -0.15rem;
        height: 0.8rem;
        width: 0.8rem;
        border-radius: 2rem;
        background-color: gray;
    }
}
.beam {
    width: 20rem;
    height: 0.5rem;
    background-color: rgb(0, 128, 0, 0.2);
    // opacity: 0.2;
    border-radius: 2rem;
    position: relative;

    .currentTime {
        height: 0.5rem;
        background-color: green;
        width: 22%;
        border-radius: 2rem;
        position: relative;
    }
}